// src/components/LanguageSelector.js
import React from 'react';
import { MenuItem, Select, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const LanguageSelector = () => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language || 'en'); // Varmista oletuskieli

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    if (i18n.changeLanguage) {
      i18n.changeLanguage(newLanguage);
    } else {
      console.error('changeLanguage function not available');
    }
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: 120,
        // "inherit" tai suoraan theme.palette.text.primary – kumpikin toimii
        color: 'inherit',
      }}
    >
      <Select
        value={language}
        onChange={handleChange}
        disableUnderline
        sx={{
          color: 'inherit',
          // Myös valikon avaus-ikoni perii saman värin
          '.MuiSelect-icon': {
            color: 'inherit',
          },
        }}
      >
        <MenuItem value="fi">Suomi</MenuItem>
        <MenuItem value="en">English</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
