// src/components/Navbar.js

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
  Switch,
  Badge,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link, useLocation } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import AppContext from '../contexts/AppContext';
import { CallRequestContext } from '../contexts/CallRequestContext';
import CloseIcon from '@mui/icons-material/Close';

function Navbar({ handleLogout, mode, toggleColorMode }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { isAdmin, isSysAdmin, userName } = useContext(AppContext);
  const { unhandledCount } = useContext(CallRequestContext);

  // Määritä, onko käyttäjä kirjautunut sisään
  const isAuthenticated = !!userName;

  // Päivitetyt välilehdet
  const tabs = [
    { label: t('callbacks'), path: '/' },
    { label: t('call_reports'), path: '/reports' },
    { label: t('statistics'), path: '/statistics' },
    { label: t('reservations'), path: '/reservations' }, // Lisätty tämä
    // Lisää tarvittaessa lisää välilehtiä
    ...(isAdmin || isSysAdmin
      ? [{ label: t('training_data'), path: '/training-data' }]
      : []),
    ...(isSysAdmin
      ? [
          { label: t('control_panel'), path: '/controlpanel' },
          { label: t('admin_panel'), path: '/admin' },
        ]
      : isAdmin
      ? [{ label: t('admin_panel'), path: '/admin' }]
      : []),
  ];

  const handleTabChange = (event, newValue) => {
    // Tämä funktio voidaan jättää tyhjäksi, koska käytämme Link-komponenttia
  };

  const toggleDrawerFunc = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawerFunc(false)}
      onKeyDown={toggleDrawerFunc(false)}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h6">{t('service_name')}</Typography>
        <IconButton onClick={toggleDrawerFunc(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {tabs.map((tab, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={tab.path}
              selected={location.pathname === tab.path}
            >
              <ListItemText primary={tab.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {(isAdmin || isSysAdmin) && (
        <>
          <Divider />
          <Box sx={{ padding: theme.spacing(2) }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: theme.spacing(2),
              }}
            >
              <Typography variant="body2">
                {mode === 'light' ? t('light_mode') : t('dark_mode')}
              </Typography>
              <Switch
                checked={mode === 'dark'}
                onChange={toggleColorMode}
                color="secondary"
                inputProps={{ 'aria-label': 'theme switch' }}
              />
            </Box>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleLogout}
              fullWidth
            >
              {t('logout')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <AppBar position="static" color="default" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
      <Toolbar>
        {isAuthenticated && isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawerFunc(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          sx={{
            flexGrow: isMobile ? 1 : 0,
            textAlign: isMobile ? 'left' : 'center',
            color: 'text.primary',
            textDecoration: 'none',
          }}
          component={Link}
          to="/"
        >
          {t('service_name')}
        </Typography>
        {isAuthenticated && !isMobile && (
          <>
            <Tabs
              value={tabs.find((tab) => tab.path === location.pathname)?.path || false}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="secondary"
              centered
              sx={{ flexGrow: 1, marginLeft: theme.spacing(2) }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  component={Link}
                  to={tab.path}
                  value={tab.path}
                  sx={{
                    minWidth: 100,
                    '&.Mui-selected': {
                      color: 'secondary.main',
                    },
                  }}
                />
              ))}
            </Tabs>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: theme.spacing(2),
                flexGrow: 0,
              }}
            >
              {/* Notifikaatiot */}
              <Tooltip title={t('notifications')}>
                <IconButton color="inherit">
                  <Badge badgeContent={unhandledCount} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              {/* Käyttäjän nimi */}
              {userName && (
                <Typography variant="body2" sx={{ ml: 1, whiteSpace: 'nowrap' }}>
                  {userName}
                </Typography>
              )}

              {/* Language Selector */}
              <Box sx={{ ml: 2 }}>
                <LanguageSelector />
              </Box>

              {/* Theme toggle */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 2,
                }}
              >
                <Typography variant="body2">
                  {mode === 'light' ? t('light_mode') : t('dark_mode')}
                </Typography>
                <Switch
                  checked={mode === 'dark'}
                  onChange={toggleColorMode}
                  color="secondary"
                  inputProps={{ 'aria-label': 'theme switch' }}
                  sx={{ ml: 1 }}
                />
              </Box>

              {/* Logout Button */}
              <Button
                color="inherit"
                onClick={handleLogout}
                sx={{ ml: 2 }}
              >
                {t('logout')}
              </Button>
            </Box>
          </>
        )}
      </Toolbar>
      {isAuthenticated && (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawerFunc(false)}
          ModalProps={{
            keepMounted: true, // Parantaa suorituskykyä mobiililaitteilla
          }}
          sx={{
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 250,
            },
          }}
        >
          {drawer}
        </Drawer>
      )}
    </AppBar>
  );
}

export default Navbar;
